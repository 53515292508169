import React from 'react';
import { css } from '@emotion/core';

export default () => (
  <div
    css={css`
      display: flex;
      flex-direction: column;
      margin: 3rem;
      color: #3f3f3f;
      h1 {
        font-size: 46px;
        text-align: center;
      }
    `}
  >
    <h1>Draft</h1>
    <h1>
      Integrate the Leading SaaS Commerce Platform With Your Content Site in
      Hours
    </h1>
    <span
      css={css`
        display: flex;
        justify-content: space-around;
      `}
    >
      <span>
        <img src='/acquia-logo.png' height='50px' alt='acquia logo' />
      </span>
      <span>
        <img src='/drupal-8-logo.png' height='100px' alt='drupal 8 logo' />
      </span>
      <span>
        <img
          src='/bigcommerce-logo.jpg'
          height='120px'
          alt='bigcommerce logo'
        />
      </span>
    </span>
    <h2>A Modern Approach to Commerce Using What You Already Know</h2>
    <p>
      The Acquia BigCommerce provides a framework you can use to add commerce
      functionality to your site rapidly using React.
    </p>
    <p>
      Because product data is imported into Drupal as regular content nodes, you
      can use the entire ecosystem of Drupal modules to manipulate, curate, and
      personalize this content in endlessly engaging ways.
    </p>
    <div
      css={css`
        height: 1px;
        width: 100%;
        margin: 1rem 0;
        background-color: black;
      `}
    />
    <span
      css={css`
        display: flex;
        justify-content: space-around;
        flex-direction: column;
      `}
    >
      <img src='/bc-products.png' height='300px' alt='bigcommerce products' />

      <img
        src='/products-in-drupal.png'
        height='300px'
        alt='products in drupal'
      />

      <img src='/store-cart.png' alt='store' />
    </span>
    <div
      css={css`
        height: 1px;
        width: 100%;
        margin: 1rem 0;
        background-color: black;
      `}
    />
    <h2>With the Acquia BigCommerce connector you can:</h2>
    <ul>
      <li>
        Show visitors suggested products related to the content being viewed by
        a visitor
      </li>
      <li>
        Provide an “add to cart” button directly in content pages in Drupal
      </li>
      <li>Offer instant checkout from content pages</li>
      <li>Embed products directly into content pages</li>
      <li>Reduce buyer friction further than has been possible</li>
      <li>Leverage content experiences to drive sales</li>
      <li>
        Carefully measure how content engagement is driving sales, finally
        closing the data reporting loop between writing content and how or
        whether it drives sales
      </li>
      <li>
        Add a shared cart to the Acquia Drupal side of your site experience that
        matches your BigCommerce store
      </li>
      <li>Add an intuitive checkout flow to your Acquia Drupal site</li>
    </ul>
    <span
      css={css`
        display: flex;
        width: 100%;
        justify-content: space-around;
      `}
    >
      <a href='https://www.thirdandgrove.com/announcing-acf-integration-acquia-bigcommerce'>
        [Read the get started guide]
      </a>
      <a href='https://www.bigcommerce.com/essentials/request-a-demo/'>
        [Connect with BigCommerce]
      </a>
    </span>
    <div
      css={css`
        height: 1px;
        width: 100%;
        margin: 1rem 0;
        background-color: black;
      `}
    />
    <p>
      Built with and supported by{' '}
      <a href='https://www.thirdandgrove.com/contact-us'>Third &amp; Grove</a>
    </p>
  </div>
);
